import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./PrivateStyle";
import { Switch, useLocation } from "react-router-dom";
import { useStateValue } from "../../contextAPI/StateProvider";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

// Routes
import privateRoutesList from "../../routes/privateRoutesList";
import PrivateRoute from "../../routes/components/PrivateRoute";
import { Routes } from "../../utils/constants";

// Components
import Sidebar from "./shared/sidebar";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_DOCTOR_DATA } from "../../graphql/queries/Doctor";

// Hooks
import useDoctorData from "../../hooks/useDoctorData";
import GenerativeAI from "./generativeAI";
import GenerativeAIMedpalm from "./generativeAIMedpalm";
import Modal from "../../utils/modal";
import ModalAddEventCalendar from "./agenda/components/modalAddEventCalendar";
import { Actions } from "../../utils/actionsIds";
import useRecordNavigation from "../../hooks/useRecordNavigation";
import ModalCreateInvitePatient from "./shared/modals/modalCreateInvitePatient";
import ModalAddOutcomesEvent from "./shared/modals/modalAddOutcomesEvent";
import FollowUp from "./followUp";
import useInfoToken from "../../hooks/useInfoToken";
import ModalSurveys from "./surveys/components/modalSurveys/ModalSurveys";

// assets
const florenceLogo = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/monaco-logo.svg`
const florenceMedpalmLogo = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/florence-medpalm.svg`

const ENV = process.env.REACT_APP_ENV;
const DOCTOR_ID = process.env.REACT_APP_DOCTOR_ID_MEDPALM_PROD;

function Private ({ authed: isAuth }) {
  const { pathname } = useLocation();
  const idPath = pathname.split('/')[1];
  const [t] = useTranslation("global");
  const [{ popUp }] = useStateValue();
  const { saveDoctorData } = useDoctorData();
  const currentPage = privateRoutesList.find(route => route.id === idPath);
  const [ activeMenu, setActiveMenu ] = useState(false);
  const { recordAction } = useRecordNavigation();
  const webView = Cookies.get('webView');

  const {doctorData} = useDoctorData();

  const { userId } = useInfoToken();

  // eslint-disable-next-line no-unused-vars
  const { data, loading, error } = useQuery(QUERY_GET_DOCTOR_DATA, { variables: { doctorId: userId} });

  const history = useHistory();
  const { CHAT } = Routes;

  // const [ aiChatbot, setAiChatbot ] = useState(false);

  const [{ 
    florenceActive,
    medpalmFlorenceActive,
    followUpActive
   }, dispatch] = useStateValue();

  const [activeShortcuts, setActiveShortcuts] = useState(false);
  const [addCalendarEvent, setAddCalendarEvent] = useState(false);
  const [createInvitePatientActive, setCreateInvitePatientActive] = useState(false);
  const [sendFillOutSurveyActive, setSendFillOutSurveyActive] = useState(false);
  const [addOutcomesEvent, setAddOutcomesEvent] = useState(false);

  useEffect(() => {
    if(!loading) {
      saveDoctorData(data?.doctor[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (ENV === 'production') {
      ReactGA.pageview(currentPage.id);
    }
  }, [currentPage]);

  const activeFlorenceChatbot = () => {
    if(activeShortcuts) {
      setFlorenceView(false);
    } else {
      recordAction(Actions.FLORENCE.FLORENCE);
      setFlorenceView(true);
    }
  }

  const activeFlorenceMedpalmChatbot = () => {
    if(activeShortcuts) {
      setMedpalmFlorenceView(false);
    } else {
      recordAction(Actions.FLORENCE.FLORENCE_MEDICOL);
      setMedpalmFlorenceView(true);
    }
  }

  const setFlorenceView = (active) => {
    dispatch({
      type: "SET_FLORENCE_ACTIVE",
      florenceActive: active,
    });
  }

  const setMedpalmFlorenceView = (active) => {
    dispatch({
      type: "SET_MEDPALM_FLORENCE_ACTIVE",
      medpalmFlorenceActive: active,
    });
  }

  const [florenceChats, setFlorenceChats] = useState([
    {
      id: 0,
      name: t("chatbotAI.general.title"),
      activateFunc: activeFlorenceChatbot,
      directActiveFunc: setFlorenceView,
      component: (props) => <GenerativeAI {...props} />,
      styleClass: "iachatbot",
      action: Actions.FLORENCE.CHAT
    }
  ]) 

  useEffect(() => {
    if(ENV !== 'production' || DOCTOR_ID === doctorData?.id) {
      florenceChats.push(
        {
          id: 1,
          name: "Medpalm",
          activateFunc: activeFlorenceMedpalmChatbot,
          directActiveFunc: setMedpalmFlorenceView,
          component: (props) => <GenerativeAIMedpalm {...props} />,
          styleClass: "iachatbot iachatbot--medpalm",
          action: Actions.FLORENCE.CHAT_MEDICOL
        }
      )
      setFlorenceChats(florenceChats);
    }
  }, [ENV])

  const [chatbotsSelected, setChabotsSelected] = useState([]);

  useEffect(() => {      
    if(medpalmFlorenceActive && !chatbotsSelected.find(chatbot => chatbot.id === florenceChats[1].id)) {
      setChabotsSelected([...chatbotsSelected, florenceChats[1]]);
    } else {
      setChabotsSelected(chatbotsSelected?.filter(chatbot => chatbot.id !== florenceChats[1].id));
    }
  }, [medpalmFlorenceActive])

   useEffect(() => {
      if(florenceActive && !chatbotsSelected.find(chatbot => chatbot.id === florenceChats[0].id)) {
        setChabotsSelected([...chatbotsSelected, florenceChats[0]]);
      } else {
        setChabotsSelected(chatbotsSelected?.filter(chatbot => chatbot.id !== florenceChats[0].id));
      }
   }, [florenceActive])

  return (
    <S.Private 
      activeModal={popUp.isActive} 
      activeMenu={activeMenu} 
      webView={webView}
      police={currentPage.id === "police"}
    >
      {/*   
        <Modal open={true}>
          <ModalPDFReader/>
        </Modal> 
      */}

      <Modal open={addOutcomesEvent}>
        <ModalAddOutcomesEvent
          setCreateEventActive={setAddOutcomesEvent}
        />
      </Modal>

      <Modal open={createInvitePatientActive}>
        <ModalCreateInvitePatient
          setCreateInvitePatientActive={setCreateInvitePatientActive}
        />
      </Modal>
      <Modal open={addCalendarEvent }>
        <ModalAddEventCalendar
          event={null}
          setCardEventSelected={null}
          setCreateEventCalendarActive={setAddCalendarEvent}
        />
      </Modal>
      <Modal open={sendFillOutSurveyActive}>
        <ModalSurveys
          setModalActive={setSendFillOutSurveyActive}
        />
      </Modal>
      <div className="private">
        {
          activeShortcuts &&
          <div className="background-extra-shortcuts" onClick={() => setActiveShortcuts(false)}></div>
        } 
        {
          !activeMenu && currentPage.id !== "police" &&
          <div className="private__shortcuts">
            <div className="shortcuts">
              {
                activeShortcuts &&
                <div className="extra-shortcut">
                   <div className="shortcut"
                    onClick={
                      () => {
                        setCreateInvitePatientActive(true);
                        recordAction(Actions.SHORTCUT.CREATE_INVITE_PATIENT);
                      }
                    }
                  >
                    <div className="shortcut__info">
                      <div className="shortcut__title">{t("globally.inviteCreatePatient")}</div>
                      <div className="shortcut__description">{t("shortcuts.addInvitePatient.description")}</div>
                    </div>
                    <button className="shortcut__button"
                      style={{backgroundColor: "#36369B"}}
                    >
                      <i className="shortcut__button-icon icon zaia-icono-perfil" />
                    </button>
                  </div>

                  <div className="shortcut"
                    onClick={
                      () => {
                        setSendFillOutSurveyActive(true);
                        recordAction(Actions.SHORTCUT.SEND_FILL_OUT_SURVEY);
                      }
                    }
                  >
                    <div className="shortcut__info">
                      <div className="shortcut__title">{t("globally.sendFillOutSurvey")}</div>
                      {/* <div className="shortcut__description">{t("shortcuts.addInvitePatient.description")}</div> */}
                    </div>
                    <button className="shortcut__button"
                      style={{backgroundColor: "#2B97FA"}}
                    >
                      <i className="shortcut__button-icon icon zaia-i-doc-1" />
                    </button>
                  </div>

                  <div className="shortcut"
                    onClick={
                      () => {
                        recordAction(Actions.SHORTCUT.CREATE_OUTCOMES_EVENT);
                        setAddOutcomesEvent(true);
                      }
                    }
                  >
                    <div className="shortcut__info">
                      <div className="shortcut__title">{t("globally.addEvent")}</div>
                      <div className="shortcut__description">{t("shortcuts.addEvent.description")}</div>
                    </div>
                    <button className="shortcut__button"
                      style={{backgroundColor: "#1F1F52"}}
                    >
                      <i className="shortcut__button-icon icon zaia-i-add-event" />
                    </button>
                  </div>


                    <div 
                      className="shortcut"
                      onClick={
                        () => {
                          history.push(CHAT.route);
                          recordAction(Actions.SHORTCUT.SEND_MESSAGE);
                          setActiveShortcuts(false);
                        }
                      }
                    >
                      <div className="shortcut__info">
                        <div className="shortcut__title">{t("shortcuts.sendMessage.title")}</div>
                        <div className="shortcut__description">{t("shortcuts.sendMessage.description")}</div>
                      </div>
                      <button className="shortcut__button"
                        style={{backgroundColor: "#6C6BCC"}}
                        >
                        <i className="shortcut__button-icon icon zaia-i-message-outline" />
                      </button>
                    </div>


                    <div 
                    className="shortcut"
                    onClick={
                      () => {
                        recordAction(Actions.SHORTCUT.CREATE_CALENDAR_EVENT);
                        setAddCalendarEvent(true)
                      }
                    }
                  >
                    <div className="shortcut__info">
                      <div className="shortcut__title">{t("shortcuts.scheduleAppointment.title")}</div>
                      <div className="shortcut__description">{t("shortcuts.scheduleAppointment.description")}</div>
                    </div>
                    <button className="shortcut__button" 
                      style={{backgroundColor: "#F19D38"}}
                    >
                      <i className="shortcut__button-icon icon zaia-i-calender-check" />
                    </button>
                  </div>
                  {/* <div 
                    className="shortcut"
                    onClick={
                      () => {
                        recordAction(Actions.SHORTCUT.CREATE_PRESCRIPTION);
                      }
                    }
                  >
                    <div className="shortcut__info">
                      <div className="shortcut__title">{t("shortcuts.sendMedications.title")}</div>
                      <div className="shortcut__description">{t("shortcuts.sendMedications.description")}</div>
                    </div>
                    <button className="shortcut__button"
                      style={{backgroundColor: "#92D46E"}}
                    >
                      <i className="shortcut__button-icon icon zaia-i-add-medicine"/>
                    </button>
                  </div> */}
                  <button className="button-action" onClick={() => setActiveShortcuts(false)}>
                    <i className="button-action__icon icon zaia-icono-cerrar" />
                  </button>
                </div>
              }
              {
                !activeShortcuts && 
                <button className="button-action" 
                  onClick={
                    () => {
                      setChabotsSelected([]);
                      setFlorenceView();
                      setMedpalmFlorenceView();
                      setActiveShortcuts(true);
                      recordAction(Actions.SHORTCUT.VIEW);
                    }
                  }
                >
                  <i className="button-action__icon icon zaia-icono-agregar" />
                </button>
              }
              {
                florenceChats.map((chat, index) => {
                  return (
                    <button 
                      key={chat.id}
                      className={chat.styleClass}
                      onClick={
                        () => {
                          chat.activateFunc()
                          recordAction(chat.action);
                        }
                      }
                    style={
                        {
                          opacity: activeShortcuts ? 0.5 : 1,
                          cursor: activeShortcuts ? "default" : "pointer"
                        }
                      }
                    >
                      <div className="iachatbot__button-logo">
                        <img className="iachatbot__button-florence-logo" src={florenceLogo} alt="F" />
                        {
                          index === 1 &&
                          <div className="iachatbot__button-florence-medpalm-logo">
                            <img className="iachatbot__button-florence-medpalm-logo-img" src={florenceMedpalmLogo} alt="M" />
                          </div>
                        }
                      </div>
                    </button>
                  )
                })
              }

              <div className="iachatbot__container">
                {
                  chatbotsSelected?.map((chatbot) => {
                    return (
                      <div className="iachatbot__box" 
                        style={
                          {
                            display: (chatbot.id === 0 ? florenceActive : medpalmFlorenceActive ) ? "flex" : "none",
                          }
                        }
                      >
                        <chatbot.component 
                          closeFunc={chatbot.directActiveFunc}
                          maxHeight="295px"
                        />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        }

        {
          followUpActive &&
          <div className="follow-up">
            <FollowUp />
          </div>
        }

        {
          currentPage.id !== "police" &&
          <div className="private__sidebar">
            <Sidebar namePage={currentPage.id} setActiveMenu={setActiveMenu} />
          </div>
        }
        
        <div className="private__right">
          <div className="private__right-body">
            <div className="private__right-body-content">
              <Switch>
                {privateRoutesList.map(privateRoute => (
                  <PrivateRoute 
                    {...privateRoute}
                    exact
                    authed={isAuth}
                  />
                ))}
              </Switch>
            </div>
            {
              popUp.isActive ? 
                <div className="private__right-body-modal">
                  <popUp.modal /> 
                </div>
              : null
            }
          </div>
        </div>
      </div>
    </S.Private>
  )

}

export default Private;
